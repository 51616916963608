<template>
  <ul class="flex md:flex-col gap-2">
    <router-link v-if="$verifyPermission(permissions, ['v2.client.project.update'])" :to="{ name: 'Project Profile', params: { id: $store.state.Projects.idRoute } }" class="flex px-2 py-2 w-full text-center md:text-left transition-all duration-300 text-gray-600 font-semibold rounded cursor-pointer" :class="[isActive === `/${$store.state.Projects.idRoute}/project-profile` ? 'bg-white shadow text-blue-400' : 'hover:text-gray-700 hover:bg-gray-200']">
      <div class="block w-auto">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" width="22" height="22" class="mx-auto">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
        </svg>
      </div>
      <span class="ml-2">{{ $t("profile.profile") }}</span>
    </router-link>
    <!--
    <router-link :to="{ name: 'Project Preferences', params: { id: $store.state.Projects.idRoute } }" class="flex px-2 py-2 w-full text-center md:text-left transition-all duration-300 text-gray-600 font-semibold rounded cursor-pointer" :class="[isActive === `/${$store.state.Projects.idRoute}/project-preferences` ? 'bg-white shadow text-blue-400' : 'hover:text-gray-700 hover:bg-gray-200']">
      <div class="block w-auto">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" width="22" height="22" class="mx-auto">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4" />
        </svg>
      </div>
      <span class="ml-2">{{ $t("preferences.preferences") }}</span>
    </router-link>
    -->
    <router-link :to="{ name: 'Project Team', params: { id: $store.state.Projects.idRoute } }" class="flex px-2 py-2 w-full text-center md:text-left transition-all duration-300 text-gray-600 font-semibold rounded cursor-pointer" :class="[isActive === `/${$store.state.Projects.idRoute}/project-team` ? 'bg-white shadow text-blue-400' : 'hover:text-gray-700 hover:bg-gray-200']">
      <div class="block w-auto">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" width="22" height="22" stroke-width="2">
          <path stroke-linecap="round" stroke-linejoin="round" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
        </svg>
      </div>
      <span class="ml-2">{{ $t("invite.team") }}</span>
    </router-link>
    <router-link v-if="$verifyPermission(permissions, ['v2.client.project.change.active.feature', 'v2.client.project.empty.collection'], 'some')" :to="{ name: 'Project Functions', params: { id: $store.state.Projects.idRoute } }" class="flex px-2 py-2 w-full text-center md:text-left transition-all duration-300 text-gray-600 font-semibold rounded cursor-pointer" :class="[isActive === `/${$store.state.Projects.idRoute}/project-functions` ? 'bg-white shadow text-blue-400' : 'hover:text-gray-700 hover:bg-gray-200']">
      <div class="block w-auto">
        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-toggle-right" width="22" height="22" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <circle cx="16" cy="12" r="2" />
          <rect x="2" y="6" width="20" height="12" rx="6" />
        </svg>
      </div>
      <span class="ml-2">{{ $t("functions.title") }}</span>
    </router-link>

    <router-link :to="{ name: 'Contact to admin', params: { id: $store.state.Projects.idRoute } }" class="flex px-2 py-2 w-full text-center md:text-left transition-all duration-300 text-gray-600 font-semibold rounded cursor-pointer" :class="[isActive === `/${$store.state.Projects.idRoute}/contact-admin` ? 'bg-white shadow text-blue-400' : 'hover:text-gray-700 hover:bg-gray-200']">
      <div class="block w-auto">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" width="22" height="22" class="mx-auto">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
        </svg>
      </div>
      <span class="ml-2">{{ $t("contacts.contactsAdmin") }}</span>
    </router-link>
  </ul>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: "SidebarProject",
  computed: {
    ...mapGetters({permissions: 'Projects/permissionsForProject'}),
  },
  data: function () {
    return {
      location: location.href,
      isActive: null
    }
  },
  mounted() {
    const url = new URL(this.location)
    const path = url.pathname
    this.isActive = path
  }
}
</script>
