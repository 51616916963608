<template>
  <div>
    <SubHeader :type="t('projectProfile.bar', {}, { locale: $store.state.Langs.selected.lang })" />
    <div class="container-xl px-2 py-2 mx-auto w-full my-4 flex flex-col">
      <div class="grid md:grid-cols-5 h-auto pb-10 gap-4">
        <div class="md:col-span-1">
          <SidebarProject />
        </div>
        <TransitionFade>
          <div class="md:col-span-4 h-auto">
            <Card>
              <template #content>
                <div class="flex flex-col w-full">
                  <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div class="form-group">
                      <label for="Name" class="block text-gray-800 font-semibold text-sm mb-2">{{ $t("projectProfile.name") }}</label>
                      <InputText v-model="company" type="text" class="p-inputtext-sm" :placeholder="$t('projectProfile.name')" />
                    </div>
                    <div class="form-group">
                      <label for="Name" class="block text-gray-800 font-semibold text-sm mb-2">{{ $t("projectProfile.cartSource") }}</label>
                      <MultiSelect v-model="cartSource" :options="sources" :filter="true" :placeholder="$t(`projectProfile.selectSource`)" class="w-full p-multiselect-icon leading-3" :showClear="true" />
                    </div>
                    <div class="form-group">
                      <label for="Name" class="block text-gray-800 font-semibold text-sm mb-2">{{ $t("projectProfile.designSource") }}</label>
                      <Dropdown v-model="designSource" :options="sources" :filter="true" :autoFilterFocus="true" :placeholder="$t('projectProfile.selectSource')" class="w-full p-multiselect-icon leading-3" />
                    </div>
                    <TransitionFade>
                      <div v-if="differentNameSuccess" class="rounded-md bg-green-50 p-4 flex justify-center items-center">
                        <div class="flex">
                          <div class="flex-shrink-0">
                            <CheckCircleIcon class="h-5 w-5 text-green-400" aria-hidden="true" />
                          </div>
                          <div class="ml-3">
                            <p class="text-sm font-medium text-green-800">
                              {{ t("profile.newName", {}, { locale: $store.state.Langs.selected.lang }) }}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div v-if="differentNameError" class="rounded-md bg-red-50 p-4 flex justify-center items-center">
                        <div class="flex">
                          <div class="flex-shrink-0">
                            <ExclamationCircleIcon class="h-5 w-5 text-red-400" aria-hidden="true" />
                          </div>
                          <div class="ml-3">
                            <p class="text-sm font-medium text-red-800">Error, please try again in a few minutes</p>
                          </div>
                        </div>
                      </div>
                    </TransitionFade>
                  </div>

                  <div class="flex">
                    <Button @click="update" class="p-button-rounded p-button-primary p-button-text" :disabled="showSpinner">
                      <strong>{{ $t('profile.btnSave') }}</strong>
                    </Button>
                    <Loader v-if="showSpinner" class="w-max ml-3" :onlyLoader="true" />
                  </div>
                </div>
              </template>
            </Card>
          </div>
        </TransitionFade>
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n"
import SubHeader from "@/components/SubHeader"
import axiosClient from "@/config/AxiosClient"
import { CheckCircleIcon, ExclamationCircleIcon } from "@heroicons/vue/solid"
import TransitionFade from "@/components/TransitionFade"
import SidebarProject from "@/components/SidebarProject"

import Card from "primevue/card"
import Button from "primevue/button"
import InputText from "primevue/inputtext"
import MultiSelect from "primevue/multiselect"
import Dropdown from "primevue/dropdown"
import Loader from '@/components/UI/Loader';

export default {
  name: "Profile",
  components: {
    SubHeader,
    TransitionFade,
    SidebarProject,
    // ErrorMessage,
    CheckCircleIcon,
    ExclamationCircleIcon,
    Loader,

    Card,
    Button,
    InputText,
    MultiSelect,
    Dropdown
  },
  setup() {
    const { t, locale } = useI18n()
    return { t, locale }
  },
  async created() {
    if (this.currentProject) await this.getProject(this.currentProject)
    let res = await axiosClient.get(`api/2.0/projects/${this.currentProject}/sources`)
    this.sources = res.data.data
    this.cartSource = this.$store.state.Projects.activeProject.data_json.cart_source ?? []
    this.designSource = this.$store.state.Projects.activeProject.data_json.design_source ?? null
  },
  data: function () {
    return {
      currentProject: this.$route.params.id ? this.$route.params.id : null,
      company: this.$store.state.Projects.activeProject?.company ? this.$store.state.Projects.activeProject.company : null,
      showSpinner: false,
      differentNameSuccess: false,
      differentNameError: false,
      sources: [],
      cartSource: [],
      designSource: null
    }
  },
  methods: {
    async getProject(id) {
      await this.$store.dispatch("Projects/getProject", id)
    },
    async update() {
      this.showSpinner = true
      let res = await axiosClient.put(`api/2.0/projects/${this.currentProject}`, {
        company: this.company,
        cart_source: this.cartSource,
        design_source: this.designSource
      })
      await this.getProject(this.currentProject)
      if (res.data.errors) {
        this.showSpinner = false
        this.differentNameError = true
        setTimeout(() => {
          this.differentNameError = false
        }, 2000)
        this.errorMessage = Object.keys(res.data.errors)
          .map(function (errorKey) {
            return res.data.errors[errorKey].join(",")
          })
          .join(",")
        this.error = true
      }
      this.showSpinner = false
      this.$toast.success(this.$t("dataSaved"))
    }
  }
}
</script>
